import styled from 'styled-components';

const Divider = styled.div`
  position: relative;

  width: 100%;
  height: 1px;
  background: rgba(43, 43, 43, 0.07);
  content: ' ';

  margin: 25px 0;
  @media (min-width: 768px) {
    margin: 45px 0;
  }
`;

function HorizontalDivider(props) {
  return <Divider {...props} />;
}

HorizontalDivider.propTypes = {};

export default HorizontalDivider;
