import PropTypes from 'prop-types';
import styled from 'styled-components';
import StarFull from '../public/svg/star-full.icon.svg';
import StarEmpty from '../public/svg/star-empty.icon.svg';
import StarHalf from '../public/svg/star-half.icon.svg';

const Wrapper = styled.div`
  display: inline-block;
  line-height: initial;
  margin-left: 2px;
`;

const StarListWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const StarWrapper = styled.div`
  width: 25px;
  &:not(:last-child) {
    margin-right: 5px;
  }
  flex: 1;

  & .star {
    fill: #ffffff;
    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.5));
  }
`;

function StarsComponent(props) {
  const maxStars = 5;

  const getFullStars = (rating) => {
    let stars = Math.floor(rating);
    if (rating % 1 > 0.7) stars += 1;
    return stars;
  };
  const addsHalfStar = (fullStars, rating) => fullStars < maxStars && rating % 1 >= 0.5;
  const getEmptyStars = (fullStars, hasHalfStar) => {
    let stars = maxStars - fullStars;
    if (hasHalfStar) stars -= 1;
    return stars;
  };

  const getStarElements = (rating) => {
    const elements = [];
    const fullStars = getFullStars(rating);
    const hasHalfStar = addsHalfStar(fullStars, rating);
    const emptyStars = getEmptyStars(fullStars, hasHalfStar);
    for (let i = 0; i < fullStars; i += 1) {
      elements.push(
        <StarWrapper key={`fullStar-${i}-${props.id}`}>
          <StarFull className="star" />
        </StarWrapper>,
      );
    }
    if (hasHalfStar)
      elements.push(
        <StarWrapper key={`halfStar-${props.id}`}>
          <StarHalf className="star" />
        </StarWrapper>,
      );
    for (let i = 0; i < emptyStars; i += 1) {
      elements.push(
        <StarWrapper key={`emptyStar-${i}-${props.id}`}>
          <StarEmpty className="star" />
        </StarWrapper>,
      );
    }
    return elements;
  };

  return (
    <Wrapper>
      <StarListWrapper>{getStarElements(props.rating)}</StarListWrapper>
    </Wrapper>
  );
}

StarsComponent.propTypes = {
  rating: PropTypes.number.isRequired,
};

export default StarsComponent;
