import styled from 'styled-components';
import PropTypes from 'prop-types';
import PagePointIndicatorComponent from './PagePointIndicatorComponent';
import { useEffect, useRef, useState } from 'react';
import ArrowFull from '../public/svg/arrow-full.icon.svg';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin-top: 15px;
`;

const NavigateElement = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  cursor: pointer;

  width: 36px;
  height: 36px;
  z-index: 91;

  border-radius: 18px;
  background-color: #ffffff;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.17);

  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  &:first-child {
    margin-right: 20px;
  }
  &:last-child {
    margin-left: 20px;
  }
`;

export default function DotPaginationComponent({ pages, indexChangedCallback }) {
  const initialIndex = 0;
  const [index, setIndex] = useState(initialIndex);
  const prevIndex = useRef(initialIndex);

  const changeCurrentIndexBy = (positionsToMove) => {
    if ((positionsToMove < 0 && index > 0) || (positionsToMove > 0 && index < pages - 1)) {
      setIndex(index + positionsToMove);
    } else if (positionsToMove < 0 && index === 0) {
      setIndex(pages - 1);
    } else if (positionsToMove > 0 && index === pages - 1) {
      setIndex(0);
    }
  };

  useEffect(() => {
    if (index !== prevIndex.current) {
      if (indexChangedCallback) indexChangedCallback(index);
      prevIndex.current = index;
    }
  }, [index, indexChangedCallback]);

  return (
    <Wrapper>
      <NavigateElement onClick={() => changeCurrentIndexBy(-1)}>
        <ArrowFull style={{ width: '9px', transform: 'rotate(180deg)', marginRight: '2px' }} />
      </NavigateElement>
      <PagePointIndicatorComponent activeIndex={index} numberOfItems={pages} />
      <NavigateElement onClick={() => changeCurrentIndexBy(1)}>
        <ArrowFull style={{ width: '9px' }} />
      </NavigateElement>
    </Wrapper>
  );
}

DotPaginationComponent.propTypes = {
  pages: PropTypes.number.isRequired,
  indexChangedCallback: PropTypes.func,
};
