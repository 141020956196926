import styled from 'styled-components';
import Image from 'next/image';
import PropTypes from 'prop-types';

import ArrowSvg from '../public/svg/arrow-right.icon.svg';
import { useRouter } from 'next/router';
import { decorateLinkOut } from '../util/helpers';

const ProductImageWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-self: center;
  img {
    object-fit: contain;
    object-position: top center;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  }

  height: 200px;
`;

const ArticleAndDescription = styled.div`
  position: relative;
  justify-self: flex-end;
  margin-bottom: 10px;
  & > h4 {
    font-weight: 700;
    font-size: 17px;
    letter-spacing: 0.75px;
    margin: 0;
  }
  & > span {
    font-weight: 300;
    letter-spacing: 0.67px;
    font-size: 13px;
    @media (min-width: 1200px) {
      font-size: 15px;
    }
  }
`;

const Spacer = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
`;

const OrderNowCTA = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  & > span {
    display: block;
    flex-grow: 1;
  }
`;

const ArrowWrapper = styled.div`
  position: relative;
  justify-self: flex-end;
  top: 0;
  right: 0;

  & > img {
    display: block;
  }
`;

const Wrapper = styled.div`
  position: relative;

  border-radius: 15px;
  box-shadow: 1px 3px 9px 0 RGBA(120, 130, 140, 0.1);
  background: rgb(183, 212, 186);
  background: linear-gradient(160deg, rgba(183, 212, 186, 0.25) 0%, rgba(255, 255, 255, 0.15) 100%);

  display: flex;
  flex-flow: column;

  cursor: pointer;
  &:hover {
    box-shadow: 1px 3px 9px 0 RGBA(120, 130, 140, 0.2);

    ${OrderNowCTA} {
      color: var(--bs-primary);
    }

    & svg {
      fill: var(--bs-primary);
    }
  }

  margin-right: 5px;
  margin-left: 5px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  margin-bottom: 40px;
  width: 220px;
  height: 305px;
  padding: 15px 20px;
  @media (min-width: 992px) {
    width: 220px;
    height: 300px;
    padding: 20px 25px;
    margin-left: 0;
    margin-right: 0;
  }
  @media (min-width: 1200px) {
    width: 230px;
    height: 320px;
  }
  @media (min-width: 1400px) {
    width: 280px;
    height: 354px;
  }
`;

const StyledLink = styled.a`
  @media (max-width: 991.98px) {
    & > ${Wrapper} {
      margin-right: 5px;
      margin-left: 5px;
    }
    &:first-child > ${Wrapper} {
      margin-left: 0;
    }
    &:last-child > ${Wrapper} {
      margin-right: 30px;
    }
  }
  @media (max-width: 575.98px) {
    &:last-child > ${Wrapper} {
      margin-right: calc(var(--bs-gutter-x) * 0.5);
    }
  }
`;

export default function ArticleBoxComponent({ imgSrc, title, subtitle, link, ...rest }) {
  const { query } = useRouter();

  return (
    <StyledLink href={decorateLinkOut(link, query)}>
      <Wrapper>
        <ProductImageWrapper>
          <Image src={imgSrc} alt="Pizza liefern lassen" />
        </ProductImageWrapper>
        <Spacer />
        <ArticleAndDescription>
          <h4>{title}</h4>
          <span>{subtitle}</span>
        </ArticleAndDescription>
        <OrderNowCTA>
          <span>Jetzt bestellen</span>
          <ArrowWrapper>
            <ArrowSvg style={{ width: '19px', height: '12px' }} />
          </ArrowWrapper>
        </OrderNowCTA>
      </Wrapper>
    </StyledLink>
  );
}

ArticleBoxComponent.propTypes = {
  imgSrc: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
