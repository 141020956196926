import { Container, Row, Col } from 'react-bootstrap';

import styled from 'styled-components';
import Image from 'next/image';

import FaLogo from '../public/svg/foodalley-de-logo.svg';
import { useRouter } from 'next/router';
import { decorateLinkOut } from '../util/helpers';

const HeaderWrapper = styled.div`
  position: fixed;
  z-index: 99;
  height: 55px;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 2px 9px 0 rgba(120, 130, 140, 0.25);

  @media (min-width: 992px) {
    height: 60px;
    line-height: 60px;
  }
`;

const HeaderContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex: 1 0 auto;
  flex-flow: row nowrap;

  align-items: center; */
`;

const LogoWrapper = styled.div`
  display: inline-block;
  position: relative;
  height: 30px;
`;

export default function Header(props) {
  const { query } = useRouter();
  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Row>
          <Col xs={12}>
            <LogoWrapper>
              <a
                href={decorateLinkOut('https://www.foodalley.de', query)}
                style={{ display: 'inline-block', height: '100%' }}
              >
                <Image src={FaLogo} alt="FoodAlley.de" height={30} />
              </a>
            </LogoWrapper>
          </Col>
        </Row>
      </HeaderContainer>
    </HeaderWrapper>
  );
}
