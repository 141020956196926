import PropTypes from 'prop-types';
import styled from 'styled-components';

const offset = 187;
const duration = '1.4s';

const Animated = styled.svg`
  animation: rotator ${duration} linear infinite;

  height: ${(props) => props.height || 65}px;

  @keyframes rotator {
    100% {
      transform: rotate(360deg);
    }
  }

  .path {
    stroke-dasharray: ${offset};
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash ${duration} ease-in-out infinite;
  }

  circle {
    fill: transparent;
    stroke-width: 4;
    stroke: ${(props) => props.stroke};
    stroke-dasharray: 283;
    stroke-linecap: round;
    transform-origin: 50% 50%;

    animation: 1.4s ease-in-out infinite both circle--animation;
  }

  @keyframes circle--animation {
    0%,
    25% {
      stroke-dashoffset: 280;
      transform: rotate(0);
    }
    50%,
    75% {
      stroke-dashoffset: 75;
      transform: rotate(45deg);
    }
    100% {
      stroke-dashoffset: 280;
      transform: rotate(360deg);
    }
  }
`;

const SVG = ({ stroke = 'var(--bs-primary)', ...rest }) => (
  <Animated
    stroke={stroke}
    className="spinner"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    style={rest.style}
  >
    <circle cx="50" cy="50" r="45" />
  </Animated>
);

SVG.propTypes = {
  stroke: PropTypes.string,
};

export default SVG;
