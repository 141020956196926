import styled from 'styled-components';
import Image from 'next/image';

import Mock1 from '../images/phone-mocks/mock1.jpg';
import Mock2 from '../images/phone-mocks/mock2.jpg';
import Mock3 from '../images/phone-mocks/mock3.jpg';
import Mock4 from '../images/phone-mocks/mock4.jpg';
import Mock5 from '../images/phone-mocks/mock5.jpg';

import ArrowFull from '../public/svg/arrow-full.icon.svg';
import PagePointIndicator from './PagePointIndicatorComponent';
import { useEffect, useMemo, useRef, useState } from 'react';

const OuterWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  user-select: none;

  flex-wrap: wrap;
`;
const Wrapper = styled.div`
  flex-grow: 0;
  position: relative;
  height: 600px;
  width: 300px;
  @media (min-width: 436px) and (max-width: 991.98px) {
    width: 348px;
    height: 700px;
  }
  @media (min-width: 1200px) {
    width: 348px;
    height: 700px;
  }
`;

const HandyWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  & img.shadowCanvas {
    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  }
  pointer-events: none;
  & * {
    pointer-events: none;
  }
`;
const MockWrapper = styled.div`
  position: absolute;
  top: 18px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  background: white;

  overflow-y: hidden;
  overflow-x: scroll;

  img {
    height: 562px;
    width: 260px;
    @media (min-width: 436px) and (max-width: 991.98px) {
      width: 308px;
      height: 666px;
    }
    @media (min-width: 1200px) {
      width: 308px;
      height: 666px;
    }
  }
  // hide scrollbars
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  display: flex;
  flex-wrap: nowrap;
  scroll-snap-type: x mandatory;
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  scroll-snap-align: start;
  display: flex;
`;

const NavigateElement = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  cursor: pointer;

  width: 36px;
  height: 36px;
  z-index: 91;

  border-radius: 18px;
  background-color: #ffffff;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.17);

  cursor: pointer;

  display: none;
  @media (min-width: 484px) {
    display: flex;
  }

  &:hover {
    transform: scale(1.05);
  }

  &:first-child {
    margin-right: 20px;
  }
  &:last-child {
    margin-left: 20px;
  }
`;

const CurrentIndexWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10px;
  margin-top: 35px;

  & > .onMobileNav {
    display: flex;
    @media (min-width: 484px) {
      display: none;
    }
  }
`;

export function PhoneSliderComponent(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollerRef = useRef();
  const slidesRef = useRef([]);

  const slidesArr = useMemo(() => [Mock1, Mock2, Mock3, Mock4, Mock5], []);

  useEffect(() => {
    if (slidesRef && slidesRef.current && slidesRef.current[currentIndex]) {
      scrollerRef.current.scrollTo({
        top: 0,
        left: slidesRef.current[currentIndex].offsetLeft,
        behavior: 'smooth',
      });
    }
  }, [currentIndex]);

  // todo hier weiter
  useEffect(() => {
    const options = {
      root: scrollerRef.current,
      rootMargin: '0px',
      threshold: 1,
    };
    const observerCallback = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        const nowShownIndex = entry.target.dataset.index;
        setCurrentIndex(parseInt(nowShownIndex, 10));
      }
    };

    const observer = new IntersectionObserver(observerCallback, options);

    slidesRef.current.forEach((_, index) => {
      if (slidesRef.current[index]) {
        observer.observe(slidesRef.current[index]);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [slidesArr]);

  const changeCurrentSlideBy = (positionsToMove) => {
    if (
      (positionsToMove < 0 && currentIndex > 0) ||
      (positionsToMove > 0 && currentIndex < slidesArr.length - 1)
    ) {
      setCurrentIndex(currentIndex + positionsToMove);
    } else if (positionsToMove < 0 && currentIndex === 0) {
      setCurrentIndex(slidesArr.length - 1);
    } else if (positionsToMove > 0 && currentIndex === slidesArr.length - 1) {
      setCurrentIndex(0);
    }
  };

  return (
    <>
      <OuterWrapper>
        <NavigateElement onClick={() => changeCurrentSlideBy(-1)}>
          <ArrowFull style={{ width: '9px', transform: 'rotate(180deg)', marginRight: '2px' }} />
        </NavigateElement>

        <Wrapper>
          <HandyWrapper>
            <Image
              src="/iPhone-case-shadow-bg.png"
              fill
              sizes="(min-width: 436px) and (max-width: 991.98px) 348px,
              (min-width: 1200px) 348px,
              300px"
              alt="iphone case"
              className="shadowCanvas"
            />
          </HandyWrapper>
          <MockWrapper ref={scrollerRef}>
            {slidesArr.map((slide, index) => (
              <ImageWrapper
                key={`slide-${index}`}
                ref={(r) => {
                  slidesRef.current[index] = r;
                }}
                data-index={index}
              >
                <Image src={slide} alt={`slide-${index}`} width="100%" />
              </ImageWrapper>
            ))}
          </MockWrapper>
          <HandyWrapper>
            <Image
              src="/iPhone-case.png"
              fill
              sizes="(min-width: 436px) and (max-width: 991.98px) 348px,
              (min-width: 1200px) 348px,
              300px"
              alt="iphone case"
            />
          </HandyWrapper>
        </Wrapper>
        <NavigateElement onClick={() => changeCurrentSlideBy(1)}>
          <ArrowFull style={{ width: '9px' }} />
        </NavigateElement>
      </OuterWrapper>
      <CurrentIndexWrapper>
        <NavigateElement onClick={() => changeCurrentSlideBy(-1)} className="onMobileNav">
          <ArrowFull style={{ width: '9px', transform: 'rotate(180deg)', marginRight: '2px' }} />
        </NavigateElement>
        <div>
          <PagePointIndicator activeIndex={currentIndex} numberOfItems={slidesArr.length} />
        </div>
        <NavigateElement onClick={() => changeCurrentSlideBy(1)} className="onMobileNav">
          <ArrowFull style={{ width: '9px' }} />
        </NavigateElement>
      </CurrentIndexWrapper>
    </>
  );
}
