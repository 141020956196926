export function resolveBasePath(path, env) {
  if (!path) return;
  const app_env = process.env.APP_ENV || env;

  let destinationPath = '';
  switch (app_env) {
    case 'production':
      destinationPath = 'https://www.foodalley.de/:path*';
      break;
    case 'staging':
      destinationPath = 'https://qa.foodalley.de/:path*';
      break;
    case 'development':
    default:
      destinationPath = 'http://localhost:3003/:path*';
      break;
  }

  return destinationPath.replace(':path*', path);
}

export default resolveBasePath;
