import Image from 'next/image';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Form, InputGroup, FormControl, Button, Container } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { decorateLinkOut } from '../util/helpers';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  height: 310px;
  background: #ffffff;
  overflow: hidden;

  clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 65px), 0% 100%);

  margin-top: 55px;
  @media (min-width: 768px) {
    height: 450px;
  }
  @media (min-width: 992px) {
    margin-top: 60px;
    height: 630px;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
`;

const ZipBoxWrapper = styled.div`
  position: absolute;
  display: flex;
  padding: 0 10px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

const ZipBox = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  max-width: 475px;

  border-radius: 4px;

  text-align: center;

  h1 {
    color: white;

    margin-bottom: 10px;
    font-size: 23px;
    letter-spacing: 1.02px;
    text-shadow: 0 1px 5px #000000;
    font-weight: 700;
    white-space: nowrap;
    @media (min-width: 768px) {
      font-size: 31px;
      letter-spacing: 1.38px;
    }
  }

  #button-addon2 {
    padding: 0 10px;
    font-size: 17px;
    font-weight: 400;
    min-width: 150px;
    letter-spacing: 0.75px;

    @media (min-width: 768px) {
      font-size: 21px;
      letter-spacing: 0.93px;
    }
  }

  .zipLabel {
    white-space: nowrap;
    font-size: 15px;
    letter-spacing: 0.67px;
    font-weight: 300;
    line-height: 24px;
    @media (min-width: 768px) {
      font-size: 21px;
      letter-spacing: 0.93px;
    }
  }
  .form-floating > input {
    border: 0;
    box-shadow: none;
    background-color: white !important;
    color: black;
    height: 45px;
    min-height: unset;
    font-size: 16px;
    @media (min-width: 768px) {
      height: 58px;
      font-size: 21px;
    }
  }
  .form-floating > .form-control:focus ~ .zipLabel,
  .form-floating > .form-control:not(:placeholder-shown) ~ .zipLabel,
  .form-floating > .form-select ~ .zipLabel {
    transform: scale(0.75) translateY(-0.5rem) translateX(0.1rem); // none !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black !important;
  }

  @media (max-width: 767.98px) {
    .zipLabel {
      white-space: nowrap;
      font-size: 15px;
      line-height: 12px;
    }

    .zip {
      height: 40px;
      padding: 10px;
      font-size: 15px;
      padding-top: 1.25rem;
    }
  }

  @media (min-width: 768px) {
    width: 660px;

    #button-addon2 {
      padding: 0 30px;
    }
  }
`;

const FormWrapper = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;

  will-change: transform, opacity;

  #btn-onlyPickUp-order {
    flex-grow: 1;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.75px;

    @media (min-width: 768px) {
      font-size: 21px;
      letter-spacing: 0.93px;
    }
  }
`;

export default function HeroBannerComponent(props) {
  const { imgSrc, filterName, zipRef } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const router = useRouter();

  const hasErrors = () => {
    return errors?.zip && true;
  };

  const getErrorMessage = (field) => {
    return field?.message || 'undefined';
  };

  const { ref: zipFormRef, ...zipRest } = register('zip', {
    required: { value: true, message: 'PLZ benötigt' },
    maxLength: { value: 5, message: 'PLZ ungültig' },
    minLength: { value: 5, message: 'PLZ ungültig' },
    pattern: { value: /^[0-9]{1,5}$/, message: 'PLZ ungültig' },
  });

  const onSubmit = ({ zip }) => {
    router.push(
      decorateLinkOut(`https://www.foodalley.de/stores/${zip}?filter=${filterName}`, router.query),
    );
  };

  return (
    <Wrapper>
      <ImageWrapper>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <Image priority as="image" src={imgSrc} alt="Title Hero Image" />
        </div>
      </ImageWrapper>

      <Container style={{ postion: 'relative' }}>
        <ZipBoxWrapper>
          <ZipBox>
            <h1>{props.title} liefern lassen</h1>

            <FormWrapper>
              <Form
                style={{ width: '100%' }}
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
              >
                <InputGroup style={{ flexWrap: 'nowrap' }}>
                  <div className="form-floating" style={{ flexGrow: 1 }}>
                    <FormControl
                      ref={(e) => {
                        zipFormRef(e);
                        zipRef.current = e;
                      }}
                      isInvalid={hasErrors()}
                      name="zip"
                      size="lg"
                      maxLength={5}
                      aria-label="Postleitzahl eingeben"
                      className="zip"
                      placeholder="Postleitzahl eingeben"
                      type="tel"
                      autoComplete="off"
                      {...zipRest}
                    />
                    {hasErrors() ? (
                      <label
                        className="zipLabel"
                        htmlFor="zip"
                        style={{ color: 'var(--bs-warning)' }}
                      >
                        {getErrorMessage(errors.zip)}
                      </label>
                    ) : (
                      <label className="zipLabel" htmlFor="zip">
                        Postleitzahl eingeben
                      </label>
                    )}
                  </div>
                  <Button variant="primary" type="submit" id="button-addon2">
                    Jetzt bestellen
                  </Button>
                </InputGroup>
              </Form>
            </FormWrapper>
          </ZipBox>
        </ZipBoxWrapper>
      </Container>
    </Wrapper>
  );
}
