export const decorateLinkOut = (link, routerQueryParams) => {
  const queryString = new URLSearchParams(routerQueryParams).toString();
  if (!queryString || queryString === '') return link;

  let prefix = '?';
  if (link.includes('?')) {
    prefix = '&';
  }
  return link + prefix + new URLSearchParams(routerQueryParams).toString();
};
