import { useCallback, useEffect, useState } from 'react';
import LoadIndicatorComponent from './LoadIndicatorComponent';
import styled from 'styled-components';
import LoadingErrorReloadComponent from './LoadingErrorReloadComponent';
import ArrowSvg from '../public/svg/arrow-right.icon.svg';
import StarsComponent from './StarsComponent';
import PropTypes from 'prop-types';
import Image from 'next/image';
import rewriteAssetPath from '../util/rewriteAssetPath';
import DotPaginationComponent from './DotPaginationComponent';
import slug from 'slug';
import resolveBasePath from '../util/resolveBasePath';
import { decorateLinkOut } from '../util/helpers';
import { useRouter } from 'next/router';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  min-height: 290px;

  margin-top: 20px;
  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

const StoreLinkWrapperA = styled.a`
  display: flex;
  width: 100%;
`;

const StoreCard = styled.div`
  display: flex;
  flex-flow: column;
  height: 347px;
  background: #ffffff;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 9px 0 RGBA(120, 130, 140, 0.15);
  overflow: hidden;

  cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 9px 0 RGBA(120, 130, 140, 0.25);
  }
`;

const StoreHeroImg = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 250px;
  background: white;

  & > img {
    object-fit: cover;
    object-position: center center;
  }
`;

const StoreDetails = styled.div`
  display: flex;
  flex-grow: 1;

  justify-content: center;
  align-items: center;
  padding: 0 25px;

  & h3 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.89px;
    color: #000000;
    margin-bottom: 15px;
  }

  & h4 {
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0.67px;
    color: #000000;
    margin-bottom: 0;
    font-style: italic;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
`;

const NameWrapper = styled.div`
  text-align: center;
  flex-grow: 1;
`;

const StarsWrapper = styled.div`
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
`;
const ImagePreloader = styled.div`
  position: relative;
  visibility: hidden;
  width: 0;
  height: 0;
`;

export default function TopRestaurantsComponent(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState();
  const [restaurantList, setRestaurantList] = useState([]);
  const [index, setIndex] = useState(0);

  const [preloadedImages, setPreloadedImages] = useState([]);

  const { zip, env } = props;
  const { query } = useRouter();

  const fetchRestaurants = useCallback(() => {
    const apiUrl = zip
      ? `/api/restaurants/v1/getByZip?zip=${zip}&limit=5&filter=${props.categoryKeyword}&needsGeo=false`
      : `/api/restaurants/v1/getTopList?limit=5&filters=${props.categoryKeyword}`;
    setLoadError(null);
    setIsLoading(true);
    fetch(apiUrl)
      .then((res) => res.json())
      .then((data) => {
        setRestaurantList(data.restaurantList || []);
        const imgArr = data.restaurantList.map((re) => (
          <Image
            src={rewriteAssetPath(re.titleImagePath, env)}
            key={`img-${re.restaurantName}`}
            alt="Title Image"
            fill
            sizes="(max-width: 576px) 100vw, (max-width: 768px) 80vw, (max-width: 992px) 33vw, 50vw"
            priority
          />
        ));
        setPreloadedImages(imgArr);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setLoadError(error);
        console.log('Error', error);
      });
  }, [props.categoryKeyword, zip, env]);

  useEffect(() => {
    fetchRestaurants();
  }, [fetchRestaurants]);

  return (
    <Wrapper>
      {isLoading || loadError || !restaurantList[index] ? (
        <>
          <LoadIndicatorComponent display={isLoading} subTitle="Top Restaurants werden geladen" />
          {loadError && <LoadingErrorReloadComponent reloadFn={fetchRestaurants} />}
        </>
      ) : (
        <>
          <StoreLinkWrapperA
            href={decorateLinkOut(
              resolveBasePath(
                `menu/${slug(restaurantList[index].restaurantName)}/${restaurantList[index]._id}`,
                env,
              ),
              query,
            )}
          >
            <StoreCard>
              <StoreHeroImg>
                {preloadedImages[index]}
                <StarsWrapper>
                  <StarsComponent rating={restaurantList[index].rating} />
                </StarsWrapper>
              </StoreHeroImg>
              <StoreDetails>
                <NameWrapper style={{ textAlign: 'center' }}>
                  <h3>{restaurantList[index].restaurantName}</h3>
                  <h4>{restaurantList[index].address.city}</h4>
                </NameWrapper>
                <ArrowWrapper>
                  <ArrowSvg style={{ width: '19px', height: '12px' }} />
                </ArrowWrapper>
              </StoreDetails>
            </StoreCard>
          </StoreLinkWrapperA>
          <DotPaginationComponent
            pages={restaurantList.length}
            indexChangedCallback={(idx) => setIndex(idx)}
          />
        </>
      )}
    </Wrapper>
  );
}

TopRestaurantsComponent.propTypes = {
  categoryKeyword: PropTypes.string.isRequired,
  zip: PropTypes.string,
  env: PropTypes.string.isRequired,
};
