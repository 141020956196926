import styled from 'styled-components';
import PropTypes from 'prop-types';
import Loader from './LoadIndicatorSvg';

const LoaderWrapper = styled.div`
  display: ${(props) => (props.display ? 'block' : 'none')};
  position: relative;
  width: 100%;
  text-align: center;

  h3 {
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 0;
    color: #000000;
  }
`;

function LoadIndicatorComponent({ display, title, subTitle, style, noText, loaderStyle }) {
  return (
    <LoaderWrapper display={display ? 1 : 0} style={style}>
      <Loader stroke="#2F9F2F" style={loaderStyle} />
      {!noText && (
        <>
          <h3 style={{ marginTop: 15 }}>{title || 'Einen Augenblick'}</h3>
          {subTitle ? <span style={{ fontWeight: 300 }}>{subTitle}</span> : null}
        </>
      )}
    </LoaderWrapper>
  );
}

LoadIndicatorComponent.propTypes = {
  display: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  style: PropTypes.object,
  loaderStyle: PropTypes.object,
  noText: PropTypes.bool,
};

LoadIndicatorComponent.defaultValues = {
  noText: false,
};

export default LoadIndicatorComponent;
