import styled from 'styled-components';
import Image from 'next/image';

import srcPosition from '../public/svg/StandortSchwarz.svg';
import srcMenu from '../public/svg/EssenSchwarz.svg';
import srcScooter from '../public/svg/RollerSchwarzFein.svg';

const Wrapper = styled.div`
  height: 20px;
  @media (min-width: 768px) {
    height: 30px;
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }
`;
const IconWrapper = styled.div`
  position: relative;
  height: 100%;
  float: left;
  margin-right: 12px;
  @media (min-width: 768px) {
    margin-right: 15px;
  }

  img {
    vertical-align: initial;
  }

  #position {
    width: 13px;
  }
  #menu {
    width: 23px;
  }
  #scooter {
    width: 30px;
  }
  @media (min-width: 768px) {
    #position {
      width: 20px;
    }
    #menu {
      width: 35px;
    }
    #scooter {
      width: 44px;
    }
  }
`;

function IconsLine(props) {
  return (
    <Wrapper {...props}>
      <IconWrapper>
        <Image src={srcPosition} alt="Position" id="position" style={{ height: '100%' }} />
      </IconWrapper>
      <IconWrapper className="clearfix">
        <Image src={srcMenu} alt="Menu" id="menu" style={{ height: '100%' }} />
      </IconWrapper>
      <IconWrapper>
        <Image src={srcScooter} alt="Scooter" id="scooter" style={{ height: '100%' }} />
      </IconWrapper>
    </Wrapper>
  );
}

IconsLine.propTypes = {};

export default IconsLine;
