import styled from 'styled-components';
import PropTypes from 'prop-types';
import RefreshIcon from '../public/svg/refresh.icon.svg';

const ErrorWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  h4 {
    margin-bottom: 0;
    font-size: 16px;
    letter-spacing: 0.7px;
  }
  span {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.61px;
  }
`;
const ErrorIconWrapper = styled.div`
  width: 66px;
  height: 74px;
  margin-bottom: 15px;
  cursor: pointer;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    transition: transform 150ms ease-in-out, scale 150ms ease-in-out;
    transform-origin: 33px 41px;
  }

  &:hover > svg {
    transform: rotate(270deg) scale(0.9);
    transform-origin: 33px 41px;
  }
`;

const LoadingErrorReloadComponent = ({ title, subtitle, reloadFn, ...rest }) => {
  return (
    <ErrorWrapper {...rest}>
      <ErrorIconWrapper onClick={reloadFn}>
        <RefreshIcon style={{ width: '100%' }} />
      </ErrorIconWrapper>
      <h4>{title || 'Fehler beim Laden'}</h4>
      <span>{subtitle || 'Bitte versuchen Sie es erneut'}</span>
    </ErrorWrapper>
  );
};

LoadingErrorReloadComponent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  reloadFn: PropTypes.func.isRequired,
};

export default LoadingErrorReloadComponent;
