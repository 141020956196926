import { Container, Row, Col, Button } from 'react-bootstrap';
import Image from 'next/image';
import HorizontalDivider from './HorizontalDivider';
import { DateTime } from 'luxon';

import styled from 'styled-components';

import srcIcon from '../public/svg/Logo-FoodAlley-INLINE-WHITE.svg';
import { useRouter } from 'next/router';
import { decorateLinkOut } from '../util/helpers';
import { useAppContext } from '../context/AppContext';

const Wrapper = styled.div`
  padding-top: 45px;
  padding-bottom: 35px;

  background-color: #484f51;
  color: #ffffff;

  & a {
    text-decoration: none;
  }
  & a:hover {
    text-decoration: underline;
  }
`;

const FooterHeading = styled.h4`
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 15px;
  margin-top: 20px;

  @media (min-width: 992px) {
    font-size: 17px;
    margin-top: 0;
    letter-spacing: 0.75px;
  }
`;

const FooterEntryP = styled.p`
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 5px;
  color: #fff;

  a {
    color: #fff !important;
  }

  @media (min-width: 992px) {
    font-size: 15px;
    letter-spacing: 0.67px;
  }
`;

const Footnote = styled(Col)`
  text-align: center;
  letter-spacing: 1.25px;

  font-weight: 300;
  font-size: 11px;
  margin-top: 15px;
`;

export default function Footer(props) {
  const { query } = useRouter();

  const { removeCookieConsent } = useAppContext();
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col
            xs="12"
            lg="3"
            style={{ display: 'flex', alignItems: 'center' }}
            className="d-none d-lg-flex"
          >
            <Image src={srcIcon} width={190} alt="FoodAlley.de" />
          </Col>

          <Col xs="12" lg="3">
            <FooterHeading style={{ marginTop: 0 }}>
              <span style={{ verticalAlign: 'middle' }}>FoodAlley</span>
            </FooterHeading>
            <FooterEntryP>
              <a href="https://my.foodalley.de" target="_blank" rel="nofollow noopener noreferrer">
                Partner Login
              </a>
            </FooterEntryP>
            <FooterEntryP>
              <a href={decorateLinkOut('https://www.foodalley.de/partner-werden', query)}>
                Partner werden
              </a>
            </FooterEntryP>
            <FooterEntryP>
              <a href={decorateLinkOut('https://www.foodalley.de/contact', query)}>
                Kontaktinformationen
              </a>
            </FooterEntryP>
          </Col>

          <Col xs="12" lg="3">
            <FooterHeading>
              <span style={{ verticalAlign: 'middle' }}>Social Media</span>
            </FooterHeading>
            <FooterEntryP>
              <a href="https://blog.foodalley.de" target="_blank" rel="noreferrer">
                Blog
              </a>
            </FooterEntryP>
            <FooterEntryP>
              <a href="https://www.facebook.com/FoodAlley.de" target="_blank" rel="noreferrer">
                Facebook
              </a>
            </FooterEntryP>
            <FooterEntryP>
              <a href="https://www.instagram.com/foodalley.de/" target="_blank" rel="noreferrer">
                Instagram
              </a>
            </FooterEntryP>
          </Col>

          <Col xs="12" lg="3">
            <FooterHeading>
              <span style={{ verticalAlign: 'middle' }}>Datenschutz</span>
            </FooterHeading>
            <FooterEntryP>
              <a href={decorateLinkOut('https://www.foodalley.de/impress', query)}>Impressum</a>
            </FooterEntryP>
            <FooterEntryP>
              <a href={decorateLinkOut('https://www.foodalley.de/privacy', query)}>
                Datenschutzerklärung
              </a>
            </FooterEntryP>
            <FooterEntryP>
              <Button variant="link" onClick={removeCookieConsent}>
                Cookie-Einstellungen
              </Button>
            </FooterEntryP>
            <FooterEntryP style={{ display: 'none' }}>Allgemeine Geschäftsbedingungen</FooterEntryP>
          </Col>
        </Row>
      </Container>

      <HorizontalDivider style={{ background: '#696969' }} />

      <Container>
        <Row>
          <Col xs="12" style={{ marginBottom: '10px' }}>
            <FooterHeading style={{ marginTop: 0 }}>
              FoodAlley Restaurants - fair Essen bestellen
            </FooterHeading>

            <Row>
              <Col xs={12} lg={3}>
                <FooterEntryP>
                  <a
                    href={decorateLinkOut(
                      'https://www.foodalley.de/stores/78054/Villingen-Schwenningen',
                      query,
                    )}
                  >
                    Restaurants in Villingen-Schwenningen
                  </a>
                </FooterEntryP>
              </Col>
              <Col xs={12} lg={3}>
                <FooterEntryP>
                  <a
                    href={decorateLinkOut('https://www.foodalley.de/stores/78628/Rottweil', query)}
                  >
                    Restaurants in Rottweil
                  </a>
                </FooterEntryP>
              </Col>
              <Col xs={12} lg={3}>
                <FooterEntryP>
                  <a
                    href={decorateLinkOut(
                      'https://www.foodalley.de/stores/72760/Reutlingen',
                      query,
                    )}
                  >
                    Restaurants in Reutlingen
                  </a>
                </FooterEntryP>
              </Col>
              <Col xs={12} lg={3}>
                <FooterEntryP>
                  <a
                    href={decorateLinkOut('https://www.foodalley.de/stores/72070/Tuebingen', query)}
                  >
                    Restaurants in Tübingen
                  </a>
                </FooterEntryP>
              </Col>
              <Col xs={12} lg={3}>
                <FooterEntryP>
                  <a
                    href={decorateLinkOut('https://www.foodalley.de/stores/75172/Pforzheim', query)}
                  >
                    Restaurants in Pforzheim
                  </a>
                </FooterEntryP>
              </Col>
              <Col xs={12} lg={3}>
                <FooterEntryP>
                  <a href={decorateLinkOut('https://www.foodalley.de/stores/78224/Singen', query)}>
                    Restaurants in Singen
                  </a>
                </FooterEntryP>
              </Col>
              <Col xs={12} lg={3}>
                <FooterEntryP>
                  <a
                    href={decorateLinkOut('https://www.foodalley.de/stores/78462/Konstanz', query)}
                  >
                    Restaurants in Konstanz
                  </a>
                </FooterEntryP>
              </Col>
              <Col xs={12} lg={3}>
                <FooterEntryP>
                  <a
                    href={decorateLinkOut('https://www.foodalley.de/stores/72336/Balingen', query)}
                  >
                    Restaurants in Balingen
                  </a>
                </FooterEntryP>
              </Col>
              <Col xs={12} lg={3}>
                <FooterEntryP>
                  <a href={decorateLinkOut('https://www.foodalley.de/stores/77933/Lahr', query)}>
                    Restaurants in Lahr
                  </a>
                </FooterEntryP>
              </Col>
              <Col xs={12} lg={3}>
                <FooterEntryP>
                  <a
                    href={decorateLinkOut('https://www.foodalley.de/stores/72555/Metzingen', query)}
                  >
                    Restaurants in Metzingen
                  </a>
                </FooterEntryP>
              </Col>
              <Col xs={12} lg={3}>
                <FooterEntryP>
                  <a
                    href={decorateLinkOut(
                      'https://www.foodalley.de/stores/71332/Waiblingen',
                      query,
                    )}
                  >
                    Restaurants in Waiblingen
                  </a>
                </FooterEntryP>
              </Col>
              <Col xs={12} lg={3}>
                <FooterEntryP>
                  <a
                    href={decorateLinkOut('https://www.foodalley.de/stores/70191/Stuttgart', query)}
                  >
                    Restaurants in Stuttgart
                  </a>
                </FooterEntryP>
              </Col>
              <Col xs={12} lg={3}>
                <FooterEntryP>
                  <a
                    href={decorateLinkOut('https://www.foodalley.de/stores/76185/Karlsruhe', query)}
                  >
                    Restaurants in Karlsruhe
                  </a>
                </FooterEntryP>
              </Col>
              <Col xs={12} lg={3}>
                <FooterEntryP>
                  <a
                    href={decorateLinkOut(
                      'https://www.foodalley.de/stores/73037/Goeppingen',
                      query,
                    )}
                  >
                    Restaurants in Göppingen
                  </a>
                </FooterEntryP>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Footnote xs={12}>
            <span>
              &copy; 2015-{DateTime.now().year}{' '}
              <a
                href={decorateLinkOut('https://www.foodalley.de', query)}
                style={{ color: '#ffffff' }}
              >
                <strong>FoodAlley.de</strong>
              </a>
            </span>
          </Footnote>
        </Row>
      </Container>
    </Wrapper>
  );
}
