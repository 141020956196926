import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { decorateLinkOut } from '../util/helpers';
import { useRouter } from 'next/router';

const FaqSection = styled.div`
  background-color: #ffffff;

  padding-top: 25px;
  padding-bottom: 45px;
  @media (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  & h4 {
    display: block;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.75px;
    margin-bottom: 15px;
  }
  & h4::after {
    content: '';
    height: 2px;
    background-color: var(--bs-primary);
    width: 35px;
    display: block;
    margin-top: 10px;
  }
`;

export const FaqComponent = (props) => {
  const { query } = useRouter();

  return (
    <FaqSection>
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <h3>FoodAlley.de als echte Alternative</h3>
            <h2 style={{ marginBottom: '40px' }}>Häufig gestellte Fragen</h2>
          </Col>
          <Col
            xs={12}
            md={6}
            itemScope
            itemProp="mainEntity"
            itemType="https://schema.org/Question"
          >
            <h4 itemProp="name">Warum bei FoodAlley.de bestellen?</h4>

            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                <p>
                  Du magst es Fair behandelt zu werden? Wir auch! Deshalb sind wir genau das zu
                  unseren Partnerbetrieben. Wir verzichten daher bewusst auf eine unverhältnismäßig
                  hohe Provision, damit hier wieder mehr für den Gastronomen übrig bleibt.
                </p>
                <p>
                  Das damit eingesparte Geld können unsere Partner dann verwenden, um z.B. die
                  Produkte oder die Produktqualität für euch zu verbessern, neue Produkte
                  anzubieten, hochwertigere Ausstattung für den Transport anzuschaffen, ihre
                  Mitarbeiter angemessen zu bezahlen oder die Preise stabil zu halten.
                </p>
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            itemScope
            itemProp="mainEntity"
            itemType="https://schema.org/Question"
          >
            <h4 itemProp="name">Wie kann ich bei FoodAlley Essen bestellen?</h4>

            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                <p>
                  Gehe dazu einfach auf unsere Website{' '}
                  <a href={decorateLinkOut('https://www.foodalley.de', query)}>www.foodalley.de</a>{' '}
                  und gib dort deine Postleitzahl ein, um zur Übersicht der Restaurants zu gelangen.
                  Du findest nun eine Vielzahl von Anbietern, welche dir dein Lieblingsessen
                  liefern.
                </p>
                <p>
                  Damit es noch einfacher wird, kannst du auch die integrierten Filter verwenden,
                  damit du bereits zu Beginn die Restaurants anhand deines Wunsches (Pizza, Burger,
                  Pasta, Sushi, o.Ä.) sortieren kannst. Schnell und einfach mit FoodAlley zu deinem
                  Lieblingsessen.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            md={6}
            itemScope
            itemProp="mainEntity"
            itemType="https://schema.org/Question"
          >
            <h4 itemProp="name">Wie kann ich bei FoodAlley bezahlen?</h4>

            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                <p>
                  Wir legen großen Wert darauf, dass alle unsere Kunden mit der Zahlungsart ihrer
                  Wahl bezahlen können. Daher haben wir neben der klassischen Bezahlung mit Bargeld
                  auch die Möglichkeit mit diversen Anbietern direkt online zu bezahlen.
                </p>
                <p>
                  Wir arbeiten stets daran, auch in Zukunft alle populären Zahlungsanbieter zu
                  integrieren. Sollte dir daher eine Zahlungsart fehlen und du bist der Meinung,
                  dass diese implementiert werden sollte, freuen wir uns über dein Feedback.
                  Schreibe uns hierzu gerne eine Nachricht über unser Kontaktformular.
                </p>
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            itemScope
            itemProp="mainEntity"
            itemType="https://schema.org/Question"
          >
            <h4 itemProp="name">Ist FoodAlley sicher?</h4>

            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                <p>
                  Und wie! Deine Daten sind bei uns sicher, dafür sorgen wir mit zahlreichen
                  Sicherheitssystemen im Hintergrund. Datenschutz hat für uns einen hohen Stellewert
                  und genießt oberste Priorität. Deine Daten sowie deine Transaktionen sind deine
                  Privatsache und daher behandeln wir alle deine Angaben mit allergrößter Vorsicht.
                </p>
                <p>
                  Lies dazu auch gerne unserer Datenschutzerklärung durch, dort ist alles ausgiebig
                  beschrieben.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </FaqSection>
  );
};
