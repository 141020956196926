import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const StyledButton = styled(Button)`
  font-size: 15px;
  position: 'relative';
  padding-left: 40;
  padding-right: 40;
  letter-spacing: '1.2px';
  height: 50;
  border-radius: '4px';

  @media (min-width: 768px) {
    font-size: 17px;
  }
`;

function CtaButtonComponent({ style, landingPageTitle, zipRef, ...rest }) {
  return (
    <StyledButton
      color="primary"
      size="lg"
      style={{ ...style }}
      onClick={() => {
        window.scrollTo({
          top: zipRef.current.offsetTop,
          behavior: 'smooth',
        });
        zipRef.current.focus({ behavior: 'smooth' });
      }}
      {...rest}
    >
      Jetzt {landingPageTitle} bestellen
    </StyledButton>
  );
}

CtaButtonComponent.propTypes = {
  style: PropTypes.object,
  landingPageTitle: PropTypes.string.isRequired,
  zipRef: PropTypes.object.isRequired,
};

export default CtaButtonComponent;
